@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700&family=Cairo:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@200;300;400;500;600;700;800;900&family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"), url("./fonts/Segoe-UI.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold"), url("./fonts/Segoe-UI-Bold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Italic"),
    url("./fonts/Segoe-UI-Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Italic Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Italic Bold"),
    url("./fonts/Segoe-UI-Bold-Italic.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Semibold"),
    url("./fonts/Segoe-UI-Semibold.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Light";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Light"),
    url("./fonts/Segoe-UI-Light.woff") format("woff");
}

/* @font-face {
  font-family: 'Selawik';
  src: url('./fonts/Selawik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Selawik Bold';
  src: local("Selawik Bold"), url('./fonts/Selawik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Selawik';
  src: url('./fonts/Selawik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Selawik';
  src: url('./fonts/Selawik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
} */

@font-face {
  font-family: 'Selawik';
  src: url('./fonts/Selawik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Selawik Bold';
  src: url('./fonts/Selawik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Selawik Semibold';
  src: url('./fonts/Selawik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Selawik Light';
  src: url('./fonts/Selawik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

:root {
  /* container width */
  --container-width: 100%;
  --max-container-width: 1024px;
  --max-wrapper-width: 1100px;
  --template-header-content-width: 100%;

   /* login background image variables */
  --login-bg-image-url: "";
  --login-bg-image-opacity: 50;
  --login-bg-image-repeat: "no-repeat";
  --login-bg-image-size: "cover";
  --login-bg-image-position: "initial";

  --login-background-color : "#202020";
  --login-background-color-rgb-values: 32, 32, 32;
  /* background image variables */
  --bg-image-url: "";
  --bg-image-opacity: 50;
  --bg-image-repeat: "no-repeat";
  --bg-image-size: "cover";
  /* current resto variables */
  /* --main-font: "SegoeUI";
  --font-regular: normal 400 var(--font-sixteen)/1.3 Segoe UI Regular, sans-serif;
  --font-semibold: normal 400 var(--font-sixteen)/1.3 Segoe UI Semibold, sans-serif;
  --font-bold:normal 400 var(--font-sixteen)/1.3 Segoe UI Bold, sans-serif;
  --font-italic: normal 400 var(--font-sixteen)/1.3 Segoe UI Italic, sans-serif;
  --font-italic-bold: normal 400 "Segoe UI Italic Bold";
  --font-light: normal 400 var(--font-sixteen)/1.3 Segoe UI Light, sans-serif; */

  --main-font: 'Selawik'; 
  --font-regular: normal 400 var(--font-sixteen)/1.3 'Selawik', sans-serif;
  --font-semibold: normal 400 var(--font-sixteen)/1.3 'Selawik X Semibold', sans-serif;  /* Semibold variant */
  --font-bold: normal 400 var(--font-sixteen)/1.3 'Selawik Bold', sans-serif;  /* Bold variant */
  --font-italic: normal 400 var(--font-sixteen)/1.3 'Selawik', sans-serif;  /* Italic variant */
  --font-italic-bold: normal 400 var(--font-sixteen)/1.3 'Selawik Bold', sans-serif; /* Italic Bold variant */
  --font-light: normal 400 var(--font-sixteen)/1.3 'Selawik Light', sans-serif;  /* Light variant */

  /* main colors */
  --main-color-rgb-values: 131, 189, 55;
  --main-color-warm: rgb(58, 120, 4);
  --main-color-warm-rgb-values: 58, 120, 4;
  --main-color-cold: rgb(194, 225, 138);
  --main-light-color: rgb(223, 233, 211);
  /* end of main colors */

  /* colors */
  --main-color: #83bd37;
  --secondary-color: #ffffff;
  --secondary-color-rgb-values: 255, 255, 255;
  --background-color: #f2f2f2;
  --background-color-rgb-values: 34, 34, 34;

  /* fonts colors */
  --text-color-over-main-color: #ffffff;
  --light-text-color-over-main-color: rgba(255,255,255,0.5);
  --cold-text-color-over-main-color: #ffffff;
  --text-color-over-secondary-color: #000000;
  --text-color-over-secondary-color-rgb-values: 0, 0, 0;
  --light-text-color-over-secondary-color: rgba(0,0,0,0.5);
  --cold-text-color-over-secondary-color: #3c3c3c;
  --text-color-over-background-color: #000000;
  --text-color-over-background-color-rgb-values: 0, 0, 0;
  --light-text-color-over-background-color: rgba(0, 0, 0, 0.5);
  --cold-text-color-over-background-color: #000000;
  --text-color-over-overlay: #ffffff;
  --text-color-over-overlay-rgb-values: 255, 255, 255;

  /* icons colors */
  --icons-color-over-main-color: #ffffff;
  --icons-color-over-secondary-color: #000000;
  --icons-color-over-background-color: #000000;

  /* skeletons colors */
  --skeleton-primary-color: #f2f2f2;
  --skeleton-secondary-color: #ffffff;
  --skeleton-terniary-color: #e0e0e0;

  /* main design variables */
  /* fonts */
  /* --font-regular-main-design: normal 400 "Segoe UI Regular";
  --font-semibold-main-design: normal 400 "Segoe UI Semibold";
  --font-bold-main-design: normal 400 "Segoe UI Bold";
  --font-italic-main-design: normal 400 "Segoe UI Italic";
  --font-italic-bold-main-design: normal 400 "Segoe UI Italic Bold";
  --font-light-main-design: normal 400 "Segoe UI Light"; */
  --font-regular-main-design: normal 400 "Selawik", sans-serif;
  --font-semibold-main-design: normal 400 "Selawik X Semibold", sans-serif;
  --font-bold-main-design: normal 400 "Selawik Bold", sans-serif;
  --font-italic-main-design: normal 400 "Selawik", sans-serif;
  --font-italic-bold-main-design: normal 400 "Selawik Bold", sans-serif;
  --font-light-main-design: normal 400 "Selawik Light", sans-serif;

  --login-background-color-rgb-values: 32, 32, 32;
  /* background image variables */
  --bg-image-url-main-design: "";
  --bg-image-opacity-main-design: 50;
  --bg-image-repeat-main-design: "no-repeat";
  --bg-image-size-main-design: "cover";

  /* main colors */
  --main-color-rgb-values-main-design: 131, 189, 55;
  --main-color-warm-main-design: #3a7804;
  --main-color-cold-main-design: #c2e18a;
  --main-light-color-main-design: rgb(223, 233, 211);
  /* end of main colors */

  /* colors */
  --main-color-main-design: #83bd37;
  --secondary-color-main-design: #ffffff;
  --secondary-color-rgb-values-main-design: 255, 255, 255;
  --background-color-main-design: #f2f2f2;
  --background-color-rgb-values-main-design: 34, 34, 34;

  /* fonts colors */
  --text-color-over-main-color-main-design: #ffffff;
  --light-text-color-over-main-color-main-design: rgba(255,255,255,0.5);
  --cold-text-color-over-main-color-main-design: #ffffff;
  --text-color-over-secondary-color-main-design: #000000;
  --text-color-over-secondary-color-rgb-values-main-design: 0, 0, 0;
  --light-text-color-over-secondary-color-main-design: rgba(0,0,0,0.5);
  --cold-text-color-over-secondary-color-main-design: #3c3c3c;
  --text-color-over-background-color-main-design: #000000;
  --text-color-over-background-color-rgb-values-main-design: 0, 0, 0;
  --light-text-color-over-background-color-main-design: rgba(0, 0, 0, 0.5);
  --cold-text-color-over-background-color-main-design: #000000;
  --text-color-over-overlay-main-design: #ffffff;
  --text-color-over-overlay-rgb-values-main-design: 255, 255, 255;

  /* icons colors */
  --icons-color-over-main-color-main-design: #ffffff;
  --icons-color-over-secondary-color-main-design: #000000;
  --icons-color-over-background-color-main-design: #000000;

  /* skeletons colors */
  --skeleton-primary-color-main-design: #f2f2f2;
  --skeleton-secondary-color-main-design: #ffffff;
  --skeleton-terniary-color-main-design: #e0e0e0;

  /* general variables */
  --tertiary-color: rgb(0, 0, 0);
  --quaternary-color: rgb(60, 60, 60);
  --disabled-color: #b4b4b4;
  --waiting-color: #e3b00a;
  --dark-red-color: #bd3737;
  --dark-red-color-disabled: rgb(189, 55, 55, 0.5);
  --warning-color: #fb1717;
  --input-border-error-color: rgb(189, 25, 25);
  --input-border-color: rgb(112, 112, 112);
  --box-shadow: #00000042 0px 6px 9px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  --light-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  /* --input-box-shadow: 0px 6px 6px #0000000f; */
  --input-box-shadow: #00000042 0px 4px 6px;
  --btn-box-shadow: #00000042 0px 3px 6px;
  --bottom-box-shadow: 0px 15px 10px -15px #00000042;
  --top-box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.2);

  --font-thirty: 30px;
  --font-twenty-six: 26px;
  --font-twenty-five: 25px;
  --font-twenty-four: 24px;
  --font-twenty-two: 22px;
  --font-twenty-one: 21px;
  --font-twenty: 20px;
  --font-nineteen: 19px;
  --font-eighteen: 18px;
  --font-seventeen: 17px;
  --font-sixteen: 16px;
  --font-fifteen: 15px;
  --font-fourteen: 14px;
  --font-thirteen: 13px;
  --font-twelve: 12px;
  --font-eleven: 11px;
  --font-ten: 10px;
  --font-nine: 9px;
  --font-eight: 8px;
  --font-six: 6px;
}

/* @media screen and (min-width: 768px) { */
@media screen and (min-width: 768px) {
  :root {
    --container-width: 95%;
    --template-container-width: 95vw;
  }
}
@media screen and (min-width: 1400px) {
  :root {
    --container-width: 85%;
    --template-container-width: 85vw;
  }
}

@media screen and (min-width: 500px) {
  :root {
    --font-thirty: 31px;
    --font-twenty-six: 27px;
    --font-twenty-five: 26px;
    --font-twenty-four: 25px;
    --font-twenty-two: 23px;
    --font-twenty-one: 22px;
    --font-twenty: 21px;
    --font-nineteen: 20px;
    --font-eighteen: 19px;
    --font-seventeen: 18px;
    --font-sixteen: 17px;
    --font-fifteen: 16px;
    --font-fourteen: 15px;
    --font-thirteen: 14px;
    --font-twelve: 13px;
    --font-eleven: 12px;
    --font-ten: 11px;
    --font-nine: 10px;
    --font-eight: 9px;
  }
}
/* 
@media screen and (min-width: 700px) {
  :root {
    --font-thirty: 32px;
    --font-twenty-six: 28px;
    --font-twenty-five: 27px;
    --font-twenty-four: 26px;
    --font-twenty-two: 24px;
    --font-twenty-one: 23px;
    --font-twenty: 22px;
    --font-nineteen: 21px;
    --font-eighteen: 20px;
    --font-seventeen: 19px;
    --font-sixteen: 18px;
    --font-fifteen: 17px;
    --font-fourteen: 16px;
    --font-thirteen: 15px;
    --font-twelve: 14px;
    --font-eleven: 13px;
    --font-ten: 12px;
    --font-nine: 11px;
    --font-eight: 10px;
    --font-six: 7px;
  }
} */

@media screen and (min-width: 768px) {
  :root {
    --font-thirty: 36px;
    --font-twenty-six: 32px;
    --font-twenty-five: 31px;
    --font-twenty-four: 30px;
    --font-twenty-two: 28px;
    --font-twenty-one: 27px;
    --font-twenty: 26px;
    --font-nineteen: 25px;
    --font-eighteen: 24px;
    --font-seventeen: 23px;
    --font-sixteen: 22px;
    --font-fifteen: 21px;
    --font-fourteen: 20px;
    --font-thirteen: 19px;
    --font-twelve: 18px;
    --font-eleven: 17px;
    --font-ten: 16px;
    --font-nine: 15px;
    --font-eight: 14px;
    --font-six: 11px;
  }
}

/* @media screen and (min-width: 940px) { */
@media screen and (min-width: 1400px) {
  :root {
    --font-thirty: 33px;
    --font-twenty-six: 29px;
    --font-twenty-five: 28px;
    --font-twenty-four: 27px;
    --font-twenty-two: 25px;
    --font-twenty-one: 24px;
    --font-twenty: 23px;
    --font-nineteen: 22px;
    --font-eighteen: 21px;
    --font-seventeen: 20px;
    --font-sixteen: 19px;
    --font-fifteen: 18px;
    --font-fourteen: 17px;
    --font-thirteen: 16px;
    --font-twelve: 15px;
    --font-eleven: 14px;
    --font-ten: 13px;
    --font-nine: 12px;
    --font-eight: 11px;
    --font-six: 8px;
  }
}

@media screen and (min-width: 940px) {
  html {
    font-size: 18px;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  user-select: none;
}

input[type="text"],
input[type="number"],
textarea,
input::placeholder,
textarea::placeholder {
  font-size: var(--font-sixteen);
  font: var(--font-regular);
  /* font-family: var(--font-regular); */
}

input:focus {
  outline: none;
}

button {
  border: none;
  cursor: pointer;
  outline: none;
  color: var(--tertiary-color);
}

a {
  text-decoration: none;
}

/*@font-face {
  font-family: 'Segoe UI Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold Italic'), url('./fonts/Segoe-UI-Bold-Italic.woff') format('woff');
} */

/* skeleton animation */

@keyframes skeleton-loading {
  0% {
    background-color: var(--skeleton-terniary-color);
  }
  50% {
    background-color: var(--skeleton-primary-color);
  }
  100% {
    background-color: var(--skeleton-secondary-color);
  }
}

/* skeleton styles */
.skeleton {
  position: relative;
}

.skeleton:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  animation: myanimation 6.5s linear 0.5s infinite normal forwards;
  border-radius: inherit;
  border: 1px solid transparent;
  overflow: hidden;
}

body[dir="rtl"] .skeleton:after {
  animation: myanimationRtl 2.5s linear 0.5s infinite normal forwards;
}

body {
  font: var(--font-regular);
  background-color: var(--background-color);
}

.skeleton.white {
  background-color: var(--skeleton-secondary-color);
}

.skeleton.dark-gray {
  background-color: rgba(0, 0, 0, 0.015);
  background-color: var(--skeleton-terniary-color);
}

.skeleton.light-gray {
  background-color: var(--skeleton-primary-color);
}

.skeleton.light-gray:after {
  background-color: rgba(0, 0, 0, 0.015);
  background-color: rgba(var(--text-color-over-background-color-rgb-values), 0.015);
}

.skeleton.dark-gray:after {
  background-color: rgba(var(--text-color-over-background-color-rgb-values), 0.025);
}

.skeleton.white:after {
  background-color: rgba(0, 0, 0, 0.025);
  background-color: rgba(var(--text-color-over-secondary-color-rgb-values), 0.025);
}

.skeleton.main-design.white {
  background-color: var(--skeleton-secondary-color-main-design);
}

.skeleton.main-design.dark-gray {
  background-color: rgba(0, 0, 0, 0.015);
  background-color: var(--skeleton-terniary-color-main-design);
}

.skeleton.main-design.light-gray {
  background-color: var(--skeleton-primary-color-main-design);
}

.skeleton.main-design.light-gray:after {
  background-color: rgba(0, 0, 0, 0.015);
  background-color: rgba(var(--text-color-over-background-color-rgb-values-main-design), 0.015);
}

.skeleton.main-design.dark-gray:after {
  background-color: rgba(var(--text-color-over-background-color-rgb-values-main-design), 0.025);
}

.skeleton.main-design.white:after {
  background-color: rgba(0, 0, 0, 0.025);
  background-color: rgba(var(--text-color-over-secondary-color-rgb-values-main-design), 0.025);
}


@keyframes myanimation {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes myanimationRtl {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.template-page-wrapper.skeleton {
  min-height: 80vh;
  background-color: var(--skeleton-terniary-color);
}

.template-main-container.skeleton {
  background-color: var(--skeleton-primary-color);
}

.template-header-title-skeleton {
  width: 8rem;
  border-radius: 5px;
  height: 1.8rem;
  margin-left: auto;
  margin-right: auto;
}

.template-header-skeleton-back {
  position: absolute;
  top: 23px;
  left: 15px;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 5px;
}

body, html {
  height: 100%;
  width: 100%;
}

body[dir="rtl"] .Toastify__toast-container--top-right {
  /* adjust toast container when on rtl */
  right: 0;
  left: 0;
}

/* template styles */
.template-page-wrapper {
  background-color: var(--main-color);
  position: relative;
  min-height: 100%;
}

@media screen and (min-width: 1024px) {
  .template-page-wrapper {
     /* add bg image, if available */
  background-image: linear-gradient(to top, rgba(var(--background-color-rgb-values), calc(1 - var(--bg-image-opacity) / 100)), rgba(var(--background-color-rgb-values), calc(1 - var(--bg-image-opacity) / 100))), var(--bg-image-url);
  background-repeat: var(--bg-image-repeat);
  background-size: var(--bg-image-size);
  background-color: var(--background-color);
  }
}

.template-header {
  height: 4.375rem;
  width: 100%;
  max-width: var(--max-wrapper-width);
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.template-header-content {
  width: var(--container-width);
  max-width: var(--max-container-width);
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .template-header-content {
    width: 91%;
  }
}

@media screen and (min-width: 1024px) {
  .template-header {
    background-color: var(--main-color);
    border-radius: 0 0 25px 25px;
    height: 6rem;
  }
  .template-header.skeleton {
    background-color: var(--skeleton-terniary-color);
  }
  .template-page-wrapper {
    background-color: var(--background-color);
  }
  .template-page-wrapper.skeleton {
    background-color: var(--skeleton-primary-color);
  }
}

@media screen and (min-width: 1150px) {
  .template-header-content {
    min-width: 1024px;
  }
}


.template-header h1 {
  margin: 0 auto;
  font: var(--font-regular);
  font-size: var(--font-twenty-five);
  font-weight: normal;
  color: var(--text-color-over-main-color);
}

.template-back-icon {
  position: absolute;
  /* top: 23px; */
  left: 15px;
  color: #fff;
  transition: all 0.5s ease;
  cursor: pointer;
  width: fit-content;
  /* margin-left: 15px;
  margin-right: -15px; */
}

body[dir="rtl"] .template-back-icon {
  left: unset;
  right: 15px;
  /* margin-right: unset;
  margin-left: -15px; */
  /* top: 17px; */
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .template-back-icon {
    left: 0; 
    right: 0;
    margin-left: unset;
    margin-right: unset;
  }
  body[dir="rtl"] .template-back-icon {
    margin-right: unset;
    right: unset;
  }
}

@media screen and (min-width: 1024px) {
  .template-header-content {
    /* width: 100vw */
  }
}

.template-main-container {
  min-height: calc(100vh - 4.375rem);
  background-color: var(--background-color);
  color: var(--text-color-over-background-color);
  border-radius: 40px 40px 0 0;
  width: 100%;
  padding: 2.2rem 1.25rem 4.5rem 1.25rem;
   background-image: linear-gradient(to top, rgba(var(--background-color-rgb-values), calc(1 - var(--bg-image-opacity) / 100)), rgba(var(--background-color-rgb-values), calc(1 - var(--bg-image-opacity) / 100))), var(--bg-image-url);
  background-repeat: var(--bg-image-repeat);
  background-size: var(--bg-image-size);
  /* background-size: clamp(1000px, 80%, 1500px) auto; */
}

@media screen and (min-width: 1024px) {
  .template-main-container {
    background-image: unset;
    background-color: transparent;
  }
}

.template-main-content {
  width: var(--container-width);
  max-width: var(--max-container-width);
  margin: 0 auto;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .template-main-content {
    width: 95%;
  }
  /* .template-header .template-back-icon {
    left: 30px;
  } */
}

/* @media screen and (min-width: 970px) { */
@media screen and (min-width: 1400px) {
  .template-main-content {
    width: 85%;
  }
  /* .template-header .template-back-icon {
    left: 40px;
  } */
}

.template-btn-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-btn {
  background-color: var(--main-color);
  color: var(--text-color-over-main-color);
  border-radius: 12px;
  font-size: var(--font-twenty-one);
  font: var(--font-semibold);
  width: 333px;
  height: 56px;
  box-shadow: var(--btn-box-shadow);
}

/* google map styles */
.gmnoprint a,
.gmnoprint span,
.gmnoprint div,
.gm-style-cc div button {
  color: rgb(145, 143, 143) !important;
}

.gmnoprint a,
.gmnoprint span {
  margin-right: 5px;
}

/* form input fields styles */
.form-section {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  font-size: var(--font-sixteen);
  font: var(--font-regular);
}

.form label {
  margin-bottom: 5px;
  /* font-size: var(--font-sixteen); */
}

.form-section input {
  /* padding: 12px; */
  /* border-radius: 7px; */
  /* border: 1px solid var(--light-text-color-over-background-color); */
  /* outline: none; */
  /* background-color: var(--background-color); */
}

.form-section input:focus,
.form-section input:hover {
  border: 2px solid var(--main-color);
}

.form-section input:focus {
  background-color: transparent;
}

/* prevent browser applying its styles on input fields when user uses autocomplete */

.form-section input:-webkit-autofill,
.form-section input:-webkit-autofill:hover,
.form-section input:-webkit-autofill:focus,
.form-section input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--background-color) inset;
  box-shadow: 0 0 0 30px var(--background-color) inset;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
  font-size: var(--font-sixteen);
}
/*  */

.form-area-wrapper {
  width: 100%;
  padding: 15px;
  border: 1px solid #838383;
  border-radius: 10px;
  background-color: var(--background-color);
}

.form-area-wrapper textarea {
  font: var(--font-regular);
  width: 100%;
  resize: none;
  height: 100%;
  border: none;
  background-color: var(--background-color);
}

.form-area-wrapper:focus-within {
  border-color: var(--main-color);
}

.form-area-wrapper textarea:focus {
  outline: none;
  border: none;
  border-color: var(--main-color);
}

.form-input-error {
  color: var(--input-border-error-color);
  padding: 0;
  font-size: var(--font-eleven);
  margin: 6px 0 0 6px;
}

.form input.error,
input.error {
  border-color: var(--input-border-error-color);
}

.body-secondary {
  background-color: var(--main-color);
}

@media screen and (min-width: 768px) {
  .body-secondary {
    background-color: var(--background-color);
  }
}

/* custom checkbox styles */
.custom-checkbox {
  min-height: 1.5rem;
  min-width: 1.5rem;
  margin-right: 0.85rem;
  border-radius: 4px;
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #393939;
}

body[dir="rtl"] .custom-checkbox {
  margin-right: unset;
  margin-left: 0.85rem;
}

.check-icon {
  color: var(--main-color);
  background-color: transparent;
  transform: scale(1.4);
}

.check-icon.check-icon-delete {
  color: var(--warning-color);
}

/* popup drawer styles */
.popup-drawer-page-wrapper {
  position: fixed !important;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  transform: translateY(100%);
  transition: all 0.3s;
}

.popup-drawer-page-wrapper.open {
  transform: translateY(0);
}

.popup-drawer-container {
  background-color: var(--secondary-color);
  color: var(--text-color-over-secondary-color);
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 25px 25px 0 0;
  z-index: 2001;
  padding: 25px 20px;
  position: absolute;
  overflow: hidden;
  max-height: 87vh;
  width: var(--container-width);
  max-width: var(--max-container-width);
  margin-left: auto;
  margin-right: auto;
}

.popup-drawer-content-container {
  overflow-y: auto;
  height: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow-x: hidden;
}

@media screen and (min-width: 500px) {
  .popup-drawer-content-container {
    align-items: center;
  }
}

.popup-drawer-container .close-icon {
  cursor: pointer;
  transition: all 0.5s;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
}

.popup-drawer-container .close-icon:hover {
  transform: scale(1.1);
}

body[dir="rtl"] .popup-drawer-container .close-icon {
  right: unset;
  left: 20px;
}

/* popup container styles */
.popup-container-page {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-container-container {
  width: 25rem;
  min-height: 10rem;
  height: fit-content;
  max-height: 90vh;
  max-width: 90%;
  border-radius: 35px;
  background-color: var(--secondary-color);
  color: var(--text-color-over-secondary-color);
  overflow: auto;
  padding: 3rem 1.5rem 1.5rem;
  position: relative;
}

.popup-container-inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1200px) {
  .popup-container-container {
    overflow: hidden;
    height: 95vh;
    max-height: fit-content;
  }
  .popup-container-inner-container {
    overflow: auto;
  }
}

.popup-container-page .close-icon {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

body[dir="rtl"] .popup-container-page .close-icon {
  right: unset;
  left: 15px;
}

/* fonts styles */
.bold {
  font: var(--font-bold);
  /* font-weight: normal; */
}

.semibold {
  font-family: var(--font-semibold);
  /* font-weight: normal; */
}

.light {
  font-family: var(--font-light);
  /* font-weight: normal; */
}

.italic {
  font-family: var(--font-italic) !important;
  /* font-style: normal !important; */
}

body[dir="rtl"] .bold {
  font-weight: bold;
}

body[dir="rtl"] .semibold {
  font-weight: 600;
}

body[dir="rtl"] .light {
  font-weight: 300;
}

body[dir="rtl"] .italic {
  font-style: italic !important;
}

/* dine in and pay home common styles */
.dine-in-home-page {
  background-color: var(--background-color);
  min-height: 100vh;
  width: 100%;
}

.dine-in-home-main {
  margin: -10px auto 0 auto;
  position: relative;
  min-height: 80vh;
}

@media screen and (min-width: 768px) {
  .dine-in-home-main {
    width: 90%;
  }
}

.dine-in-home-main .dine-in-body {
  width: 100%;
  padding: 10px 20px 200px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dine-in-body-options-container {
  width: 100%;
  margin-top: 2rem;
  max-width: 500px;
  padding: 0 1rem;
}

/*  */
.dine-in-home-main .dine-in-body .body-option-text {
  margin-left: 3px;
}

.dine-in-home-main .dine-in-body .bill-section .body-option-text {
  margin-left: 8px;
}

body[dir="rtl"] .dine-in-home-main .dine-in-body .body-option-text {
  margin-left: unset;
  margin-right: 3px;
}

body[dir="rtl"]
  .dine-in-home-main
  .dine-in-body
  .bill-section
  .body-option-text {
  margin-left: unset;
  margin-right: 8px;
}

.dine-in-home-main .dine-in-body a {
  color: unset;
}

.close-btn {
  font: var(--font-semibold);
  font-size: var(--font-twelve);
  text-decoration: underline;
  background-color: var(--secondary-color);
  color: var(--text-color-over-secondary-color);
  cursor: pointer;
}

body[dir="rtl"] .close-btn {
  font-weight: 600;
}

.bg-image {
  background-image: linear-gradient(to top, rgba(var(--background-color-rgb-values), calc(1 - var(--bg-image-opacity) / 100)), rgba(var(--background-color-rgb-values), calc(1 - var(--bg-image-opacity) / 100))), var(--bg-image-url);

  background-repeat: var(--bg-image-repeat);

  background-size: var(--bg-image-size);
  /* background-size: clamp(1000px, 80%, 1500px) auto;
  background-size: 100% clamp(100vh, 100%, 2000px); */

  background-color: var(--background-color);
}

.main-bg-image {
  background-image: linear-gradient(to top, rgba(var(--background-color-rgb-values-main-design), calc(1 - var(--bg-image-opacity-main-design) / 100)), rgba(var(--background-color-rgb-values-main-design), calc(1 - var(--bg-image-opacity-main-design) / 100))), var(--bg-image-url-main-design);

  background-repeat: var(--bg-image-repeat-main-design);

  background-size: var(--bg-image-size-main-design);
  /* background-size: clamp(1000px, 80%, 1500px) auto;
  background-size: 100% clamp(100vh, 100%, 2000px); */

  background-color: var(--background-color-main-design);
}

.login-bg-image {
    background-image: linear-gradient(to top, rgba(var(--login-background-color-rgb-values), calc(1 - var(--login-bg-image-opacity) / 100)), rgba(var(--login-background-color-rgb-values), calc(1 - var(--login-bg-image-opacity) / 100))), var(--login-bg-image-url);

  background-repeat: var(--login-bg-image-repeat);

  background-size: var(--login-bg-image-size);
  background-position: var(--login-bg-image-position);
  /* background-size: clamp(1000px, 80%, 1500px) auto;
  background-size: 100% clamp(100vh, 100%, 2000px); */

  background-color: var(--login-background-color);
}

/* Hide the native scrollbar */
body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* Create a transparent scrollbar */
body::-webkit-scrollbar {
  width: 0; /* Safari and Chrome */
}

.overlay-scrollbar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 16px; /* Adjust as needed */
  background-color: var(--background-color); /* Match the background color of your page */
  z-index: 9999; /* Ensure it's above other content */
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Prevent interactions with underlying elements */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.overlay-scrollbar.show {
  opacity: 1; /* Show scrollbar overlay */
}


/* scrollbar styles */
@media (hover: hover) {
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-light-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--main-color-cold);
}

::-webkit-scrollbar-track {
  background-color: #ddd;
}

/* custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--main-light-color);
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--main-color-cold);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #ddd;
  border-radius: 6px;
}
}

/* custom notification styles */
/* .custom-toast-container {
  background-color: var(--secondary-color) !important;
  color: var(--text-color-over-secondary-color) !important; 

  max-width: 95%;
  margin:8px;
}

.custom-toast-body {  
  background-color: var(--secondary-color);
  color: var(--text-color-over-secondary-color);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.notification-body {
  font: var(--font-regular);
  font-size: var(--font-ten);
  margin: 10px 10px 0 25px; 
}

.notification-icon-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.notification-icon-container {
  margin-right: 5px;
}
.notification-title {
  font: var(--font-semibold);
  font-size: var(--font-eleven);
}

.notification-image-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.notification-image-container img {
  border-radius: 8px;
  width: 50px;
}


 */

 .custom-toast-container {
  background-color: var(--secondary-color) !important;
  color: var(--text-color-over-secondary-color) !important; 
  max-width: 95%;
  margin:8px;
}

.notification-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-body {
  font: var(--font-regular);
  font-size: var(--font-ten);
  margin: 10px 10px 0 25px; 
}

.notification-icon-title {
  display: flex;
  margin-bottom: 5px;
}

.notification-icon-container {
  margin-right: 5px;
}
.notification-title {
  font: var(--font-semibold);
  font-size: var(--font-twelve);
}

.notification-image-container {
  display: flex;
}

.notification-image-container img {
  border-radius: 8px;
  height:  50px;
  aspect-ratio: 1/1;
}

@media screen and (min-width: 900px) {
  .notification-container {
    display: block;
  }
  .notification-image-container {
    width: 100%;
    display: flex;
    justify-content: fl;
  }
}

/* qty controllers styles */
@media screen and (min-width: 365px) {
  .qty-controllers-wrapper {
    margin-left: 0;
  }
}

.qty-controllers-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  z-index: 900;
  color: var(--text-color-over-secondary-color);
}

.qty-controllers-wrapper .qty-controllers-btn-minus {
  margin-right: 5px;
}

.qty-controllers-btn-plus {
  margin-left: 5px;
  padding-top: 2px;
}

.qty-controllers-btn {
  cursor: pointer;
  background-color: var(--secondary-color);
  color: var(--text-color-over-secondary-color);
  border: 1px solid var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  text-align: center;
  width: 30px;
  font-size: var(--font-twenty-one);
  text-align: center;
  height: 26px;
  line-height: 1;
  outline: none;
}

.qty-controllers-qty {
  min-width: 25px;
  font-size: var(--font-fifteen);
  text-align: center;
}


@media screen and (min-width: 365px) {
  .qty-controllers-wrapper {
    margin-left: 0;
  }
}

.qty-controllers-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.qty-controllers-wrapper .qty-controllers-btn-minus {
  margin-right: 5px;
}

.qty-controllers-btn-plus {
  margin-left: 5px;
  padding-top: 2px;
}

.qty-controllers-btn {
  cursor: pointer;
  background-color: var(--secondary-color);
  border: 1px solid var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  text-align: center;
  width: 30px;
  font-size: var(--font-twenty-one);
  text-align: center;
  height: 26px;
  line-height: 1;
  outline: none;
}
