.sk-cart-bar {
  background: radial-gradient(
    30px 30px at 50% 145%,
    rgba(255, 255, 255, 0) 42px,
    var(--skeleton-secondary-color) 38.5px
  );
  width: calc(100% - 12px);
  border-radius: 9px;
  margin: 0 auto 33px auto;
  border: none;
  height: 60px;
  max-width: var(--max-container-width);
  width: calc(var(--container-width) - 4%);
}

.sk-cart-bar.no-media {
  background-color: var(--skeleton-secondary-color);
  box-shadow: var(--box-shadow);
  margin-bottom: 15px;
}

/* @media screen and (min-width: 850px) {
  .sk-cart-bar {
    width: 550px;  }
} */

@media screen and (min-width: 1024px) {
  .sk-cart-bar, .cart-bar.skeleton {
    display: none;
  }
}

.cart-bar.skeleton .cart-bar-left,
.cart-bar.skeleton .cart-bar-right {
  background-color: var(--skeleton-secondary-color);
}


.cart-bar.skeleton .cart-bar-middle::before {
  box-shadow: 0px 0px 0px 74px var(--skeleton-secondary-color);
}

.cart-bar.skeleton.no-media .cart-bar-middle {
  background-color: var(--skeleton-secondary-color);
}