.sk-item-container {
  /* width: calc((100% - 80px) / 4); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--skeleton-secondary-color);
  min-height: 260px;
  border-radius: 10px;
}

.sk-item-container .sk-item-image {
  height: 160px;
  background-color: var(--skeleton-terniary-color);
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-radius: 10px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .sk-item-container {
    min-height: 405px;
  }

  .sk-item-container .sk-item-image {
    height: 270px;
  }
}

@media screen and (min-width: 1200px) {
  .sk-item-container {
    min-height: 315px;
  }

  .sk-item-container .sk-item-image {
    height: 200px;
  }
}


.sk-item-container .sk-item-title {
  width: 5.1875em;
  height: 1em;
  background-color: var(--skeleton-primary-color);
  border-radius: 3px;
  margin: 5px;
  margin-top: -25px;
}

.sk-item-container .sk-item-price {
  border-radius: 3px;
  width: 2.5625em;
  height: 0.8125em;
  background-color: var(--skeleton-primary-color);
  align-self: flex-end;
  margin-right: 5px;
  margin-bottom: 5px;
}

body[dir="rtl"] .sk-item-container .sk-item-price {
  margin-right: unset;
  margin-left: 5px;
}