.cart-bar {
  color: var(--text-color-over-main-color);  
  /* width: 96%; */
  width: calc(var(--container-width) - 4%);
  max-width: var(--max-container-width);
  border-radius: 9px;
  margin: 0 auto 32px auto;
  /* margin: 0 20px 32px 20px; */
  font-size: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: none;
  background-color: transparent;
  height: 50px;
  /* max-width: 580px; */
  padding: 7px 15px;
  font-size: var(--font-thirteen);
  position: relative;
  height: 60px;
  cursor: pointer;
}

.cart-bar.no-media {
  background-color: var(--main-color);
  color: var(--text-color-over-main-color);
  box-shadow: var(--box-shadow);
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .cart-bar {
    height: 4rem;
  }
}

.cart-bar.hidden {
  display: none;
}

@media screen and (min-width: 768px) {
  .cart-bar {
    display: none;
  }
}

.cart-bar-skltn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.cart-bar-left,
.cart-bar-right {
  width: calc(100% - 38px);
  height: 100%;
  background-color: var(--main-color);
}

.cart-bar-left {
  width: calc(100% - 37px);
  border-radius: 10px 0 0 10px;
  margin-left: -1px;
}

.cart-bar.rtl .cart-bar-left {
  border-radius: 0 10px 10px 0;
}

.cart-bar-right {
  width: calc(100% - 37.5px);
  border-radius: 0 10px 10px 0;
  margin-right: -0.5px;
}

.cart-bar.rtl .cart-bar-right {
  border-radius: 10px 0 0 10px;
}

.cart-bar-middle {
  width: 76px;
  height: 100%;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  border: none;
  display: flex;
  flex-shrink: 0;
  border: 0px;
}

.cart-bar-middle::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  align-self: flex-start;
  top: -50%;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 0px 0px 74px var(--main-color);
  transform: translateY(125%);
}

.cart-bar.rtl .cart-bar-middle::before {
  right: 1px;
}

@media screen and (min-width: 768px) {
  .cart-bar-middle {
    background-color: var(--secondary-color);
  }
  .cart-bar-middle::before {
    content: none;
  }
}

.cart-bar-content {
  width: 100%;
  position: abolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 200;
}
/* .cart-bar > div {
  display: inline-block;
  width: 33%;
  text-align: left;
  vertical-align: top;
} */
.cart-bar .cart-items {
  text-transform: capitalize;
}
.cart-bar > div.cart-price {
  text-align: center;
}
.cart-bar > div.cart-icon {
  text-align: right;
}

.cart-price {
  padding-right: 15px;
}

.cart-bar.rtl .cart-price {
  padding-right: unset;
  padding-left: 15px;
}

.cart-icon svg {
  color: #fff;
  font-size: 18px;
}

/* @media screen and (min-width: 850px) {
  .cart-bar {
    width: 550px;
  }
} */
