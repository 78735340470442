.sk-bottom-bar {
  position: fixed;
  top: auto;
  bottom: 0;
  z-index: 999;
  text-align: center;
  margin: 0 auto;
  right: 0;
  left: 0;
  width: 100%;
  transform: translate(0, 0);
}

.sk-bottom-bar .sk-bottom-bar-footer {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .sk-bottom-bar {
    /* max-width: 550px; */
  }
}

.sk-bottom-bar .sk-circle-btn {
  position: absolute;
  width: 56px;
  height: 56px;
  top: -30px;
  left: calc(50% - 28px);
  background-color: var(--skeleton-primary-color);
  border-radius: 50%;
}

.sk-bottom-bar .sk-media-circle {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 32px);
  bottom: 58px;
  background-color: var(--skeleton-secondary-color);
}

@media screen and (min-width: 768px) {
  .sk-bottom-bar .sk-circle-btn {
    display: none;
  }
  .sk-bottom-bar .sk-media-circle {
    width: 58px;
    height: 58px;
    bottom: 26px;
    background-color: var(--skeleton-primary-color);
  }
}


.sk-bottom-links {
  height: 88px;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px 5px 0 0;
  position: relative;
  background: radial-gradient(
    ellipse 35px 31px at 50% 4px,
    var(--skeleton-primary-color) 43px,
    var(--skeleton-secondary-color) 45px
  ); 
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--top-box-shadow);
}

@media screen and (min-width: 768px) {
  .sk-bottom-links {
    background: var(--skeleton-secondary-color);
  }
}

.sk-bottom-bar.no-media .sk-bottom-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: var(--top-box-shadow);
  background: var(--secondary-color);
}

.sk-bottom-links .sk-left-links,
.sk-bottom-links .sk-right-links {
  display: flex;
  width: calc(50% - 43px);
  align-items: center;
  justify-content: space-around;
  height: 100%;
  padding-bottom: 15px;
}

.sk-bottom-links .sk-bar-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.sk-bottom-links .sk-bar-link-cart {
  display: none;
}

@media screen and (min-width: 768px) {
  .sk-bottom-links .sk-bar-link-cart {
  display: flex;
}
}

.sk-bottom-links .sk-bar-link .sk-icon {
  width: 1.5rem;
  height: 1.125rem;
  background-color: var(--skeleton-primary-color);
}

.sk-bottom-links .sk-bar-link .sk-link {
  width: 2rem;
  height: 0.55rem;
  margin-top: 5px;
  background-color: var(--skeleton-primary-color);
}
